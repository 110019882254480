import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    StyleSheet,
    Dimensions,
    Image,
    TouchableOpacity,
} from 'react-native';
import { f_notas, g_notas, m_notas } from '../data/notas';
import IFrags from '../interfaces/IFrags';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import IFinal from '../interfaces/IFinal';
import { AttrFH, AttrInts } from '../providers/image_provider';
import MaskedView from '@react-native-masked-view/masked-view';

const Icons = MaterialCommunityIcons;
const { width, height } = Dimensions.get('screen');

const Final: React.FC<IFinal> = ({ type, nota, frag, onBack }) => {
    const [selectedValue, setSelectedValue] = useState<string>("Seleccione una nota");
    const [isHidden, setHidden] = useState<boolean>(true);
    const [text, setText] = useState("");

    return (
        <LinearGradient style={styles.container}
            colors={["rgba(112, 34, 131, 1)", "rgba(232, 47, 137, 1)", "rgba(229, 35, 28, 1)"]}>
            <View style={styles.card}>
                <TouchableOpacity onPress={onBack} style={styles.backButton}>
                    <View style={styles.button_img_container}>
                        <Image source={require('../images/arrow-down-circle-fill.png')}
                            style={{ flex: 1 }} resizeMode='contain' />
                    </View>
                    <Text style={styles.back}>VOLVER A EMPEZAR</Text>
                </TouchableOpacity>
                <Text style={styles.finalText}>4/4 Final</Text>

                <View style={{ paddingLeft: 110 }}>
                    <MaskedView
                        style={{ height: 140, width: width * 0.85 }}
                        maskElement={<Text style={styles.title}>ESTA ES TU{'\n'}FRAGANCIA IDEAL</Text>}
                    >
                        <LinearGradient
                            colors={["rgba(112, 34, 131, 1)", "rgba(232, 47, 137, 1)", "rgba(232, 47, 137, 1)"]}
                            start={{ x: 0, y: 1 }}
                            end={{ x: 1, y: 0 }}
                            style={{ flex: 1 }}
                        />
                    </MaskedView>
                    <Text style={{ fontSize: 30, fontWeight: 'bold', color: '#e9318a' }}>{
                        type === "genderless" ? (
                            frag?.includes("Cítrica") ? (
                                "Attraction One Fresh EDP"
                            ) : frag?.includes("Oriental") ? (
                                'Attraction One Intense EDP'
                            ) : null
                        ) : type === "masculinas" ? (
                            frag === "Amaderada" ? (
                                nota === "Cuero y cardamomo" ?
                                    "Black Suede Dark  EDT" :
                                    nota === "Jengibre, almizcle y Cumarú" ?
                                        "Attraction for Him EDT" :
                                        nota === "Cuero y Whiskey" ?
                                            "Segno EDP" : null
                            ) : frag === "Aromática" ? (
                                nota === "Ozónicas, menta y vetiver" ?
                                    "300 KM/H SURFER EDT" : null
                            ) : frag === "Fougére" ? (
                                nota === "Especias y almizcle" ?
                                    "300 KM/H Turbo EDT" :
                                    nota === "Hierbas frescas, ámbar dorado y cuero" ?
                                        "Wild Country EDT" :
                                        nota === "Cardamomo, sándalo y madera" ?
                                            "Exclusive in Blue EDT" :
                                            nota === "Maderas, ambar y especias cítricas" ?
                                                "Exclusive Original EDP" :
                                                null
                            ) : frag === "Oriental" ? (
                                nota === "Cítricas, cuero y madera" ? (
                                    "Black Suede EDT "
                                ) : null
                            ) : null
                        ) : type === "femeninas" ? (
                            frag === "Amaderada" ? (
                                nota === "Frambuesa, pimienta rosa y orquídea" ?
                                    "Attraction para Ella EDP" : null
                            ) : frag === "Chypre" ? (
                                nota === "Cítricas, jazmín y vainilla" ?
                                    "Imari EDT" : null
                            ) : frag === "Floral" ? (
                                nota === "Fresia, peonia Blanca y madera de Sándalo" ?
                                    "Pur Blanca EDT" :
                                    nota === "Pétalos de flores blancas y frutas" ?
                                        "TTA This Love EDP" :
                                        nota === "Flores blancas, fresias y madera" ?
                                            "TTA Today EDP" :
                                            nota === "Limon, Pera, Margaritas, y Almizcle" ?
                                                "Pur Blanca Liberté EDT" :
                                                nota === "Mandarina, Flores Neroli y Magnolias, Ambar y Almizcle" ?
                                                    "TTA The Moment EDP for Her" : null
                            ) : frag === "Oriental" ? (
                                nota === "Flores exóticas y madera de sándalo" ? (
                                    "Far Away EDP"
                                ) : nota === "Vainilla, sándalo, Pera y Jazmín" ? (
                                    "Far Away Beyond Parfum"
                                ) : null
                            ) : null
                        ) : null
                    }</Text>
                    <Text style={{ color: '#502583' }}>{nota}</Text>
                </View>
                <View style={{ flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <View style={{ position: 'relative', flex: 1, paddingLeft: 30, paddingRight: 20}}>
                        <View style={{
                            width: 300, height: 500,
                            justifyContent: 'center', alignItems: 'center'
                        }}>
                            <Image source={
                                type === "genderless" ? (
                                    frag?.includes("Cítrica") ? (
                                        require("../images/frags/attractionFresh.png")
                                    ) : frag?.includes("Oriental") ? (
                                        require("../images/frags/attractionIntense.png")
                                    ) : null
                                ) : type === "masculinas" ? (
                                    frag === "Amaderada" ? (
                                        nota === "Cuero y cardamomo" ?
                                            require("../images/frags/suedeBlack.png") :
                                            nota === "Jengibre, almizcle y Cumarú" ?
                                                require("../images/frags/attractionForHim.png") :
                                                nota === "Cuero y Whiskey" ?
                                                    require("../images/frags/segno.png") : null
                                    ) : frag === "Aromática" ? (
                                        nota === "Ozónicas, menta y vetiver" ?
                                            require("../images/frags/300kmSurfer.png") : null
                                    ) : frag === "Fougére" ? (
                                        nota === "Especias y almizcle" ?
                                            require("../images/frags/300kmTurbo.png") :
                                            nota === "Hierbas frescas, ámbar dorado y cuero" ?
                                                require("../images/frags/wildCountry.png") :
                                                nota === "Cardamomo, sándalo y madera" ?
                                                    require("../images/frags/exclusiveInBlue.png") :
                                                    nota === "Maderas, ambar y especias cítricas" ?
                                                        require("../images/frags/ExclusiveOriginal.png") :
                                                        null
                                    ) : frag === "Oriental" ? (
                                        nota === "Cítricas, cuero y madera" ? (
                                            require("../images/frags/suede.png")
                                        ) : null
                                    ) : null
                                ) : type === "femeninas" ? (
                                    frag === "Amaderada" ? (
                                        nota === "Frambuesa, pimienta rosa y orquídea" ?
                                            require("../images/frags/attractionParaElla.png") : null
                                    ) : frag === "Chypre" ? (
                                        nota === "Cítricas, jazmín y vainilla" ?
                                            require("../images/frags/imari.png") : null
                                    ) : frag === "Floral" ? (
                                        nota === "Fresia, peonia Blanca y madera de Sándalo" ?
                                            require("../images/frags/purBlanca.png") :
                                            nota === "Pétalos de flores blancas y frutas" ?
                                                require("../images/frags/TTAthislove.png") :
                                                nota === "Flores blancas, fresias y madera" ?
                                                    require("../images/frags/TTAtoday.png") :
                                                    nota === "Limon, Pera, Margaritas, y Almizcle" ?
                                                        require("../images/frags/purBlanca.png") :
                                                        nota === "Mandarina, Flores Neroli y Magnolias, Ambar y Almizcle" ?
                                                            require("../images/frags/TTAthemoment.png") : null
                                    ) : frag === "Oriental" ? (
                                        nota === "Flores exóticas y madera de sándalo" ? (
                                            require("../images/frags/farAwayBeyond.png")
                                        ) : nota === "Vainilla, sándalo, Pera y Jazmín" ? (
                                            require("../images/frags/farAwayBeyond.png")

                                        ) : null
                                    ) : null
                                ) : null
                            } style={{ flex: 1 }} resizeMode='contain' />
                        </View>
                    </View>
                    <View style={{ flex: 1 , paddingHorizontal: 30}}>
                        <View style={{ alignItems: 'center', flexDirection: 'row'}}>
                            <View style={{
                                width: 40, height: 40                                
                            }}>
                                <Image style={{flex: 1}} resizeMode='contain' source={require('../images/arrow-down-left-circle-fill-1.png')} />
                            </View>
                            <Text style={{color: '#e9318a', fontWeight: 'bold', letterSpacing: 1, fontSize: 18}}>{type?.split('').map((val, index) => index === 0 ? val.toUpperCase() : val)}</Text>                            
                        </View>
                        <View style={{ alignItems: 'center', flexDirection: 'row', marginTop: 40}}>
                            <View style={{
                                width: 40, height: 40                                
                            }}>
                                <Image style={{flex: 1}} resizeMode='contain' source={require('../images/arrow-down-left-circle-fill-1.png')} />
                            </View>
                            <Text style={{color: '#e9318a', fontWeight: 'bold', letterSpacing: 1, fontSize: 18}}>{frag}</Text>                            
                        </View>
                        <View style={{ alignItems: 'center', flexDirection: 'row', marginTop: 40}}>
                            <View style={{
                                width: 40, height: 40                                
                            }}>
                                <Image style={{flex: 1}} resizeMode='contain' source={require('../images/arrow-down-left-circle-fill-1.png')} />
                            </View>
                            <Text style={{color: '#e9318a', fontWeight: 'bold', letterSpacing: 1, fontSize: 18}}>{nota}</Text>                            
                        </View>
                    </View>
                </View>
                <View style={{
                    width: width * 0.8,
                    flexDirection: 'row',
                    justifyContent: 'space-evenly', alignItems: 'center',
                    position: 'absolute',
                    bottom: 12,
                }}>
                    <Image source={require('../images/logo.png')}/>
                    <View style={{ marginLeft: 50 }}>
                        <Text style={styles.slogan}>
                            Conocé cuál es {'\n'}tu fragancia{'\n'}ideal.
                        </Text>
                    </View>
                </View>
            </View>
        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    container: {
        width, height,
        backgroundColor: '#f1f1f1',
        justifyContent: 'center',
        alignItems: 'center'
    },
    card: {
        height: height * 0.85,
        width: width * 0.85,
        backgroundColor: '#ffffff',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopLeftRadius: 50,
        borderBottomRightRadius: 50,
        elevation: 3,
        position: 'relative'
    },
    title: {
        fontSize: 60,
        color: 'rgb(85,36,130)',
        fontWeight: 'bold',
        position: 'relative',
        zIndex: 2
    },
    signAbs: {
        fontSize: 250,
        position: 'absolute',
        zIndex: 0,
        top: 150,
        color: 'white',
        textShadowColor: '#FF43A4',
        textShadowRadius: 2,
    },
    sxButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button_img_container: {
        width: 40,
        height: 40,
    },
    gender: {
        fontSize: 24,
        color: '#712282',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        letterSpacing: 2,
    },
    buttons_container: {
        marginTop: 120,
        marginHorizontal: 120,
        position: 'relative',
        zIndex: 1,
        transform: [
            { translateY: -20 }
        ],
        width: 260,
        height: 60,
        alignItems: 'center',
    },
    backButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 24,
        left: 24
    },
    back: {
        fontSize: 16,
        paddingLeft: 12,
        color: '#bb156a'
    },
    dropbutton: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderWidth: 2,
        borderColor: '#eb4e9b',
        width: 260,
        height: 60,
        padding: 12,
        borderTopLeftRadius: 20,
        borderBottomRightRadius: 20
    },
    dropContent: {
        marginTop: 0,
        backgroundColor: '#fff2f9',
        width: 260,
        borderRadius: 12,
        overflow: 'hidden'
    },
    dropContentButton: {
        padding: 8,
    },
    finalText: {
        position: 'absolute',
        top: 24,
        right: 24,
        fontSize: 16,
        color: '#e9318a'
    },
    slogan: {
        fontSize: 16,
        color: "rgb(243,100,168)",
        lineHeight: 20
    },
});

export default Final;