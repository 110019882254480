import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    StyleSheet,
    Dimensions,
    Image,
    TouchableOpacity,
} from 'react-native';
import { f_notas, g_notas, m_notas } from '../data/notas';
import IFrags from '../interfaces/IFrags';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import MaskedView from '@react-native-masked-view/masked-view';

const Icons = MaterialCommunityIcons;
const { width, height } = Dimensions.get('screen');

const amaderada = ['Cuero y cardamomo', 'Jengibre, almizcle y Cumarú', 'Cuero y Whiskey'];
const aromatica = ['Ozónicas, menta y vetiver'];
const fougere = ["Especias y almizcle", "Hierbas frescas, ámbar dorado y cuero", "Cardamomo, sándalo y madera", "Maderas, ambar y especias cítricas"];
const oriental = ["Cítricas, cuero y madera"];
const citrica = ["Lima brasileña, higo y madera de cedro"];
const oriental_g = ["Bergamota, cardamomo y sándalo"];

const amaderada_f = ["Frambuesa, pimienta rosa y orquídea"];
const chypre = ['Cítricas, jazmín y vainilla'];
const floral = ["Fresia, peonia Blanca y madera de Sándalo", 'Pétalos de flores blancas y frutas', 'Flores blancas, fresias y madera', 'Limon, Pera, Margaritas, y Almizcle', 'Mandarina, Flores Neroli y Magnolias, Ambar y Almizcle'];
const oriental_f = ['Flores exóticas y madera de sándalo', 'Vainilla, sándalo, Pera y Jazmín'];

const Notas: React.FC<IFrags> = ({ onBack, type, callback, frag }) => {
    const [selectedValue, setSelectedValue] = useState<string>("Seleccione una nota");
    const [isHidden, setHidden] = useState<boolean>(true);


    return (
        <View style={styles.container}>
            <View style={styles.card}>
                <TouchableOpacity onPress={onBack} style={styles.backButton}>
                    <View style={styles.button_img_container}>
                        <Image source={require('../images/arrow-down-circle-fill.png')}
                            style={{ flex: 1 }} resizeMode='contain' />
                    </View>
                    <Text style={styles.back}>Atrás</Text>
                </TouchableOpacity>
                <Image source={require('../images/group.png')} style={{ position: 'absolute', top: 0, right: 0 }} />
                <Image source={require('../images/notas_elec.png')} style={{ position: 'absolute', bottom: 0, left: 0 }} />
                <View style={{
                    width: width * 0.85,
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    <MaskedView
                        style={{
                            height: 160, width: width * 0.85, position: 'relative', zIndex: 1,
                        }}
                        maskElement={<Text style={styles.title}>
                            CUÁLES SON LAS{'\n'}NOTAS OLFATIVAS{'\n'}QUE MÁS TE GUSTAN
                        </Text>}
                    >
                        <LinearGradient
                            colors={["rgba(112, 34, 131, 1)", "rgba(232, 47, 137, 1)", "rgba(232, 47, 137, 1)"]}
                            start={{ x: 0, y: 1 }}
                            end={{ x: 1, y: 0 }}
                            style={{ flex: 1 }}
                        />
                    </MaskedView>
                </View>

                <View style={[styles.buttons_container, {
                    overflow: isHidden ? "hidden" : 'visible'
                }]}>
                    <TouchableOpacity activeOpacity={0.7} onPress={() => setHidden(!isHidden)} style={styles.dropbutton}>
                        <Text style={{ color: '#eb4e9b', fontWeight: 'bold' }}>{selectedValue}</Text>
                        <Icons name="chevron-down" size={24} color='#eb4e9b' />
                    </TouchableOpacity>
                    <View style={styles.dropContent}>
                        {
                            type === "masculinas" ? (
                                frag === "Amaderada" ? (
                                    m_notas.map((val, index) =>
                                        amaderada.map(v => {
                                            if (val === v) {
                                                return (
                                                    <TouchableOpacity activeOpacity={0.7} style={[styles.dropContentButton, {
                                                        backgroundColor: index % 2 == 0 ? "#fff2f9" : "#fffefe"
                                                    }]}
                                                        key={index.toString()} onPress={() => {
                                                            setSelectedValue(val);
                                                            setHidden(!isHidden);
                                                            callback(val);
                                                            setSelectedValue('Seleccione una nota');
                                                        }}>
                                                        <Text style={{ color: "#f07eb7" }}>{val}</Text>
                                                    </TouchableOpacity>
                                                );
                                            }
                                        })
                                    )
                                ) : frag === "Aromática" ? (
                                    m_notas.map((val, index) =>
                                        aromatica.map(v => {
                                            if (val === v) {
                                                return (
                                                    <TouchableOpacity activeOpacity={0.7} style={[styles.dropContentButton, {
                                                        backgroundColor: index % 2 == 0 ? "#fff2f9" : "#fffefe"
                                                    }]}
                                                        key={index.toString()} onPress={() => {
                                                            setSelectedValue(val);
                                                            setHidden(!isHidden);
                                                            callback(val);
                                                            setSelectedValue('Seleccione una nota');
                                                        }}>
                                                        <Text style={{ color: "#f07eb7" }}>{val}</Text>
                                                    </TouchableOpacity>
                                                );
                                            }
                                        })
                                    )
                                ) : frag === "Fougére" ? (
                                    m_notas.map((val, index) =>
                                        fougere.map(v => {
                                            if (val === v) {
                                                return (
                                                    <TouchableOpacity activeOpacity={0.7} style={[styles.dropContentButton, {
                                                        backgroundColor: index % 2 == 0 ? "#fff2f9" : "#fffefe"
                                                    }]}
                                                        key={index.toString()} onPress={() => {
                                                            setSelectedValue(val);
                                                            setHidden(!isHidden);
                                                            callback(val);
                                                            setSelectedValue('Seleccione una nota');
                                                        }}>
                                                        <Text style={{ color: "#f07eb7" }}>{val}</Text>
                                                    </TouchableOpacity>
                                                );
                                            }
                                        })
                                    )
                                ) : frag === "Oriental" ? (
                                    m_notas.map((val, index) =>
                                        oriental.map(v => {
                                            if (val === v) {
                                                return (
                                                    <TouchableOpacity activeOpacity={0.7} style={[styles.dropContentButton, {
                                                        backgroundColor: index % 2 == 0 ? "#fff2f9" : "#fffefe"
                                                    }]}
                                                        key={index.toString()} onPress={() => {
                                                            setSelectedValue(val);
                                                            setHidden(!isHidden);
                                                            callback(val);
                                                            setSelectedValue('Seleccione una nota');
                                                        }}>
                                                        <Text style={{ color: "#f07eb7" }}>{val}</Text>
                                                    </TouchableOpacity>
                                                );
                                            }
                                        })
                                    )
                                ) : null
                            ) : type === "genderless" ? (
                                frag === "Cítrica" ? (
                                    g_notas.map((val, index) =>
                                        citrica.map(v => {
                                            if (v === val) {
                                                return (
                                                    <TouchableOpacity activeOpacity={0.7} style={[styles.dropContentButton, {
                                                        backgroundColor: index % 2 == 0 ? "#fff2f9" : "#fffefe"
                                                    }]}
                                                        key={index.toString()} onPress={() => {
                                                            setSelectedValue(val);
                                                            setHidden(!isHidden);
                                                            callback(val);
                                                            setSelectedValue('Seleccione una nota');
                                                        }}>
                                                        <Text style={{ color: "#f07eb7" }}>{val}</Text>
                                                    </TouchableOpacity>
                                                );
                                            }
                                        })
                                    )
                                ) : frag === "Oriental" ? (
                                    g_notas.map((val, index) =>
                                        oriental_g.map(v => {
                                            if (v === val) {
                                                return (
                                                    <TouchableOpacity activeOpacity={0.7} style={[styles.dropContentButton, {
                                                        backgroundColor: index % 2 == 0 ? "#fff2f9" : "#fffefe"
                                                    }]}
                                                        key={index.toString()} onPress={() => {
                                                            setSelectedValue(val);
                                                            setHidden(!isHidden);
                                                            callback(val);
                                                            setSelectedValue('Seleccione una nota');
                                                        }}>
                                                        <Text style={{ color: "#f07eb7" }}>{val}</Text>
                                                    </TouchableOpacity>
                                                );
                                            }
                                        })
                                    )
                                ) : null
                            ) : type === "femeninas" ? (
                                frag === "Amaderada" ? (
                                    f_notas.map((val, index) =>
                                        amaderada_f.map(v => {
                                            if (val === v) {
                                                return (
                                                    <TouchableOpacity activeOpacity={0.7} style={[styles.dropContentButton, {
                                                        backgroundColor: index % 2 == 0 ? "#fff2f9" : "#fffefe"
                                                    }]}
                                                        key={index.toString()} onPress={() => {
                                                            setSelectedValue(val);
                                                            setHidden(!isHidden);
                                                            callback(val);
                                                            setSelectedValue('Seleccione una nota');
                                                        }}>
                                                        <Text style={{ color: "#f07eb7" }}>{val}</Text>
                                                    </TouchableOpacity>
                                                );
                                            }
                                        })
                                    )
                                ) : frag === "Chypre" ? (
                                    f_notas.map((val, index) =>
                                        chypre.map(v => {
                                            if (val === v) {
                                                return (
                                                    <TouchableOpacity activeOpacity={0.7} style={[styles.dropContentButton, {
                                                        backgroundColor: index % 2 == 0 ? "#fff2f9" : "#fffefe"
                                                    }]}
                                                        key={index.toString()} onPress={() => {
                                                            setSelectedValue(val);
                                                            setHidden(!isHidden);
                                                            callback(val);
                                                            setSelectedValue('Seleccione una nota');
                                                        }}>
                                                        <Text style={{ color: "#f07eb7" }}>{val}</Text>
                                                    </TouchableOpacity>
                                                );
                                            }
                                        })
                                    )
                                ) : frag === "Floral" ? (
                                    f_notas.map((val, index) =>
                                        floral.map(v => {
                                            if (val === v) {
                                                return (
                                                    <TouchableOpacity activeOpacity={0.7} style={[styles.dropContentButton, {
                                                        backgroundColor: index % 2 == 0 ? "#fff2f9" : "#fffefe"
                                                    }]}
                                                        key={index.toString()} onPress={() => {
                                                            setSelectedValue(val);
                                                            setHidden(!isHidden);
                                                            callback(val);
                                                            setSelectedValue('Seleccione una nota');
                                                        }}>
                                                        <Text style={{ color: "#f07eb7" }}>{val}</Text>
                                                    </TouchableOpacity>
                                                );
                                            }
                                        })
                                    )
                                ) : frag === "Oriental" ? (
                                    f_notas.map((val, index) =>
                                        oriental_f.map(v => {
                                            if (val === v) {
                                                return (
                                                    <TouchableOpacity activeOpacity={0.7} style={[styles.dropContentButton, {
                                                        backgroundColor: index % 2 == 0 ? "#fff2f9" : "#fffefe"
                                                    }]}
                                                        key={index.toString()} onPress={() => {
                                                            setSelectedValue(val);
                                                            setHidden(!isHidden);
                                                            callback(val);
                                                            setSelectedValue('Seleccione una nota');
                                                        }}>
                                                        <Text style={{ color: "#f07eb7" }}>{val}</Text>
                                                    </TouchableOpacity>
                                                );
                                            }
                                        })
                                    )
                                ) : null
                            ) : null
                        }
                    </View>
                </View>

                <Text style={[styles.signAbs, { right: 120 }]}>?</Text>
                <Text style={[styles.signAbs, {
                    left: 120,
                    transform: [{ rotateZ: '180deg' }, { translateY: -14 }]
                }]}>?</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width, height,
        backgroundColor: '#f1f1f1',
        justifyContent: 'center',
        alignItems: 'center'
    },
    card: {
        height: height * 0.85,
        width: width * 0.85,
        backgroundColor: '#ffffff',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopLeftRadius: 50,
        borderBottomRightRadius: 50,
        elevation: 3,
        position: 'relative'
    },
    title: {
        fontSize: 45,
        color: 'rgb(85,36,130)',
        fontWeight: 'bold',
        position: 'relative',
        zIndex: 2,
        textAlign: 'center'
    },
    signAbs: {
        fontSize: 250,
        position: 'absolute',
        zIndex: 0,
        top: 150,
        color: 'white',
        textShadowColor: '#FF43A4',
        textShadowRadius: 2,
    },
    sxButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button_img_container: {
        width: 40,
        height: 40,
    },
    gender: {
        fontSize: 24,
        color: '#712282',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        letterSpacing: 2,
    },
    buttons_container: {
        marginTop: 120,
        marginHorizontal: 120,
        position: 'relative',
        zIndex: 1,
        transform: [
            { translateY: -20 }
        ],
        width: 260,
        height: 60,
        alignItems: 'center',
    },
    backButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 24,
        left: 24
    },
    back: {
        fontSize: 16,
        paddingLeft: 12,
        color: '#bb156a'
    },
    dropbutton: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderWidth: 2,
        borderColor: '#eb4e9b',
        width: 260,
        height: 60,
        padding: 12,
        borderTopLeftRadius: 20,
        borderBottomRightRadius: 20
    },
    dropContent: {
        marginTop: 0,
        backgroundColor: '#fff2f9',
        width: 260,
        borderRadius: 12,
        overflow: 'hidden'
    },
    dropContentButton: {
        padding: 8,
    }
});

export default Notas;