import React, { useEffect, useRef, useState } from 'react';
import { StyleSheet, Text, View, StatusBar, ScrollView, Dimensions } from 'react-native';
import Final from './components/scrolleables/Final';
import Frags from './components/scrolleables/Frags';
import Gender from './components/scrolleables/Gender';
import Main from './components/scrolleables/Main';
import Notas from './components/scrolleables/Notas';

export default function App() {
  const [type, setType] = useState<string>('');
  const [frag, setFrag] = useState<string>('');
  const [nota, setNota] = useState<string>('');
  const scrollViewRef = useRef<ScrollView>(null);

  // event: NativeSyntheticEvent<NativeScrollEvent>
  const handleOnScroll = (mult: number = 1) => {
    // const index = event.nativeEvent.contentOffset.y / Dimensions.get('window').height;
    // console.log('currentScreenIndex', index);
    scrollViewRef?.current?.scrollTo({ y: Dimensions.get('window').height * mult, animated: true });
  }
  const handleOnScrollBack = () => {
    scrollViewRef?.current?.scrollTo({ y: -Dimensions.get('window').height, animated: true });
  }

  useEffect(() => {
    console.log(type, frag, nota);
  }, [type, frag, nota]);

  return (
    <View style={styles.container}>
      <StatusBar translucent backgroundColor="rgba(0,0,0,0.2)" barStyle="light-content" />
      <ScrollView
        showsVerticalScrollIndicator={false}
        bounces={false}
        pagingEnabled
        scrollEnabled={false}
        ref={scrollViewRef}
      >
        <Main onPress={() => {
          handleOnScroll(1);
        }} />
        <Gender onBack={() => {
          handleOnScrollBack();
        }} callback={(call: string) => {
          handleOnScroll(2);
          setType(call);
        }} />
        <Frags onBack={handleOnScrollBack} callback={(call: string) => {
          handleOnScroll(3);
          setFrag(call);
        }} type={type} />
        <Notas frag={frag} onBack={handleOnScrollBack} callback={(call: string) => {
          handleOnScroll(4);
          setNota(call);
        }} type={type} />
        <Final onBack={handleOnScrollBack} type={type} frag={frag} nota={nota} />
      </ScrollView>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    flex: 1,
    backgroundColor: '#ebf1f3',
  },
});
