const g_fragancies = [
    "Cítrica",
    "Oriental"
];

const m_fragancies = [
    "Amaderada",
    "Fougére",
    "Oriental",
    "Aromática"
];

const f_fragancies = [
    "Amaderada",
    "Chypre",
    "Floral",
    "Oriental"
]


export { g_fragancies, m_fragancies, f_fragancies };