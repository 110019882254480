import { LinearGradient } from 'expo-linear-gradient';
import React from 'react';
import {
    View,
    Text,
    StyleSheet,
    Dimensions,
    Image,
    TouchableOpacity
} from 'react-native';
import MainParams from '../interfaces/IMainParams';

const { width, height } = Dimensions.get('screen');

const Main: React.FC<MainParams> = ({ onPress }) => {
    return (
        <LinearGradient style={styles.container}
            colors={["rgba(112, 34, 131, 1)", "rgba(232, 47, 137, 1)", "rgba(229, 35, 28, 1)"]}>
            <View style={styles.card}>
                <View style={styles.middleContent}>
                    <View style={styles.left_container}>
                        <View style={styles.logo_container}>
                            <Image source={require('../images/logo-avon.png')} style={{ flex: 1 }} resizeMode="contain" />
                        </View>
                        <View style={{ flexDirection: 'row' }}>
                            <Text style={styles.logo_text}>Mirá </Text>
                            <Text style={[styles.logo_text, {
                                color: 'rgb(236,10,126)',
                                textShadowRadius: 0
                            }]}> de nuevo</Text>
                        </View>
                    </View>
                    <View style={styles.vertLine}></View>
                    <View style={{ marginLeft: 50 }}>
                        <Text style={styles.slogan}>
                            Conocé cuál es {'\n'}tu fragancia{'\n'}ideal.
                        </Text>
                    </View>
                </View>
                <TouchableOpacity onPress={onPress} style={styles.footerButton}>
                    <View>
                        <Image source={require('../images/arrow-down-circle-fill.png')} style={{
                            transform: [
                                { rotateZ: '-90deg' }
                            ]
                        }} />
                    </View>
                    <View style={{ paddingLeft: 12 }}>
                        <Text style={{ color: 'rgb(130,2,125)', fontWeight: 'bold', fontSize: 10 }}>CLICK PARA</Text>
                        <Text style={{
                            color: '#ffffff',
                            fontWeight: 'bold',
                            textShadowColor: 'rgb(130,2,125)',
                            textShadowRadius: 3,
                            fontSize: 24,
                            textAlign: 'center'
                        }}>COMENZAR</Text>
                    </View>
                </TouchableOpacity>
            </View>
        </LinearGradient>
    );
}

const styles = StyleSheet.create({
    container: {
        width, height,
        backgroundColor: 'rgba(229, 35, 28, 1)',
        justifyContent: 'center',
        alignItems: 'center'
    },
    card: {
        height: height * 0.85,
        width: width * 0.85,
        backgroundColor: '#ffffff',
        justifyContent: 'center',
        alignItems: 'center',
        borderBottomLeftRadius: 50,
        borderTopRightRadius: 50,
        elevation: 3,
        position: 'relative'
    },
    left_container: {
        justifyContent: 'center',
        alignItems: 'center',
        transform: [
            { translateY: 25 }
        ]
    },
    logo_container: {
        width: 300,
        height: 100,
        justifyContent: 'center',
        alignItems: 'center'
    },
    logo_text: {
        fontWeight: 'bold',
        textTransform: 'uppercase',
        color: '#ffffff',
        textShadowColor: 'rgba(229, 35, 28, 1)',
        textShadowRadius: 2,
        letterSpacing: 1,
        fontSize: 20,
        transform: [
            { translateY: -50 }
        ]
    },
    vertLine: {
        width: 2,
        height: 120,
        backgroundColor: 'rgba(227,156,192, 0.4)'
    },
    middleContent: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        transform: [
            { translateX: -45 }
        ]
    },
    slogan: {
        fontSize: 16,
        color: "rgb(243,100,168)",
        lineHeight: 30
    },
    footerButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        bottom: 60
    }
});

export default Main;