import { LinearGradient } from 'expo-linear-gradient';
import React, { useEffect, useState } from 'react';
import {
    View,
    Text,
    StyleSheet,
    Dimensions,
    Image,
    TouchableOpacity,
    StatusBar,
} from 'react-native';
import { f_fragancies, g_fragancies, m_fragancies } from '../data/frags';
import IFrags from '../interfaces/IFrags';
import { MaterialCommunityIcons } from '@expo/vector-icons';
import MaskedView from '@react-native-masked-view/masked-view';

const Icons = MaterialCommunityIcons;
const { width, height } = Dimensions.get('screen');

const Frags: React.FC<IFrags> = ({ onBack, type, callback }) => {
    const [selectedValue, setSelectedValue] = useState<string>("Seleccione una familia");
    const [isHidden, setHidden] = useState<boolean>(true);
    const [unmmount, setUnmmount] = useState<boolean>(false);


    return (
        <View style={styles.container}>
            <View style={styles.card}>
                <TouchableOpacity onPress={onBack} style={styles.backButton}>
                    <View style={styles.button_img_container}>
                        <Image source={require('../images/arrow-down-circle-fill.png')}
                            style={{ flex: 1 }} resizeMode='contain' />
                    </View>
                    <Text style={styles.back}>Atrás</Text>
                </TouchableOpacity>
                <Image source={require('../images/group.png')} style={{ position: 'absolute', top: 0, right: 0 }} />
                <Image source={require('../images/fam_elec.png')} style={{ position: 'absolute', bottom: 0, left: 0 }} />
                <View style={{
                    width: width * 0.85,
                    justifyContent: 'center', alignItems: 'center'
                }}>
                    <MaskedView
                        style={{
                            height: 140, width: width * 0.85, position: 'relative', zIndex: 1,
                        }}
                        maskElement={<Text style={styles.title}>
                            QUÉ FRAGANCIA{'\n'}TE GUSTA MÁS
                        </Text>}
                    >
                        <LinearGradient
                            colors={["rgba(112, 34, 131, 1)", "rgba(232, 47, 137, 1)", "rgba(232, 47, 137, 1)"]}
                            start={{ x: 0, y: 1 }}
                            end={{ x: 1, y: 0 }}
                            style={{ flex: 1 }}
                        />
                    </MaskedView>
                </View>

                <View style={[styles.buttons_container, {
                    overflow: isHidden ? "hidden" : 'visible'
                }]}>
                    <TouchableOpacity activeOpacity={0.7} onPress={() => setHidden(!isHidden)} style={styles.dropbutton}>
                        <Text style={{ color: '#eb4e9b', fontWeight: 'bold' }}>{selectedValue}</Text>
                        <Icons name="chevron-down" size={24} color='#eb4e9b' />
                    </TouchableOpacity>
                    <View style={styles.dropContent}>
                        {
                            type === "masculinas" ? (
                                m_fragancies.map((val, index) => {
                                    return (
                                        <TouchableOpacity activeOpacity={0.7} style={[styles.dropContentButton, {
                                            backgroundColor: index % 2 == 0 ? "#fff2f9" : "#fffefe"
                                        }]}
                                            key={index.toString()} onPress={() => {
                                                setSelectedValue(val);
                                                setHidden(!isHidden);
                                                callback(val);
                                                setSelectedValue("Seleccione una familia");
                                            }}>
                                            <Text style={{ color: "#f07eb7" }}>{val}</Text>
                                        </TouchableOpacity>
                                    );
                                })
                            ) : type === "genderless" ? (
                                g_fragancies.map((val, index) => {
                                    return (
                                        <TouchableOpacity activeOpacity={0.7} style={[styles.dropContentButton, {
                                            backgroundColor: index % 2 == 0 ? "#fff2f9" : "#fffefe"
                                        }]}
                                            key={index.toString()} onPress={() => {
                                                setSelectedValue(val);
                                                setHidden(!isHidden);
                                                callback(val);
                                                setSelectedValue("Seleccione una familia");
                                            }}>
                                            <Text style={{ color: "#f07eb7" }}>{val}</Text>
                                        </TouchableOpacity>
                                    );
                                })
                            ) : type === "femeninas" ? (
                                f_fragancies.map((val, index) => {
                                    return (
                                        <TouchableOpacity activeOpacity={0.7} style={[styles.dropContentButton, {
                                            backgroundColor: index % 2 == 0 ? "#fff2f9" : "#fffefe"
                                        }]}
                                            key={index.toString()} onPress={() => {
                                                setSelectedValue(val);
                                                setHidden(!isHidden);
                                                callback(val);
                                                setSelectedValue("Seleccione una familia");
                                            }}>
                                            <Text style={{ color: "#f07eb7" }}>{val}</Text>
                                        </TouchableOpacity>
                                    );
                                })
                            ) : null
                        }
                    </View>
                </View>

                <Text style={[styles.signAbs, { right: 120 }]}>?</Text>
                <Text style={[styles.signAbs, {
                    left: 120,
                    transform: [{ rotateZ: '180deg' }, { translateY: -14 }]
                }]}>?</Text>
            </View>
        </View>
    );
}

const styles = StyleSheet.create({
    container: {
        width, height,
        backgroundColor: '#f1f1f1',
        justifyContent: 'center',
        alignItems: 'center'
    },
    card: {
        height: height * 0.85,
        width: width * 0.85,
        backgroundColor: '#ffffff',
        justifyContent: 'center',
        alignItems: 'center',
        borderTopLeftRadius: 50,
        borderBottomRightRadius: 50,
        elevation: 3,
        position: 'relative'
    },
    title: {
        fontSize: 45,
        color: 'rgb(85,36,130)',
        fontWeight: 'bold',
        position: 'relative',
        zIndex: 2,
        textAlign: 'center'
    },
    signAbs: {
        fontSize: 250,
        position: 'absolute',
        zIndex: 0,
        top: 150,
        color: 'white',
        textShadowColor: '#FF43A4',
        textShadowRadius: 2,
    },
    sxButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
    },
    button_img_container: {
        width: 40,
        height: 40,
    },
    gender: {
        fontSize: 24,
        color: '#712282',
        textTransform: 'uppercase',
        fontWeight: 'bold',
        letterSpacing: 2,
    },
    buttons_container: {
        marginTop: 120,
        marginHorizontal: 120,
        position: 'relative',
        zIndex: 1,
        transform: [
            { translateY: -20 }
        ],
        width: 260,
        height: 60,
        alignItems: 'center',
    },
    backButton: {
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        position: 'absolute',
        top: 24,
        left: 24
    },
    back: {
        fontSize: 16,
        paddingLeft: 12,
        color: '#bb156a'
    },
    dropbutton: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
        borderWidth: 2,
        borderColor: '#eb4e9b',
        width: 260,
        height: 60,
        padding: 12,
        borderTopLeftRadius: 20,
        borderBottomRightRadius: 20
    },
    dropContent: {
        marginTop: 0,
        backgroundColor: '#fff2f9',
        width: 260,
        borderRadius: 12,
        overflow: 'hidden'
    },
    dropContentButton: {
        padding: 8,
    }
});

export default Frags;