const g_notas = [
    "Lima brasileña, higo y madera de cedro",
    "Bergamota, cardamomo y sándalo"
];

const m_notas = [
    "Cuero y cardamomo",
    "Jengibre, almizcle y Cumarú",
    "Cuero y Whiskey",
    "Ozónicas, menta y vetiver",
    "Especias y almizcle",
    "Hierbas frescas, ámbar dorado y cuero",
    "Cardamomo, sándalo y madera",
    "Maderas, ambar y especias cítricas",
    "Cítricas, cuero y madera"
];

const f_notas = [
    "Frambuesa, pimienta rosa y orquídea",
    "Cítricas, jazmín y vainilla",
    "Fresia, peonia Blanca y madera de Sándalo",
    "Pétalos de flores blancas y frutas",
    "Flores blancas, fresias y madera",
    "Limon, Pera, Margaritas, y Almizcle",
    "Mandarina, Flores Neroli y Magnolias, Ambar y Almizcle",
    "Flores exóticas y madera de sándalo",
    "Vainilla, sándalo, Pera y Jazmín"
]


export { g_notas, m_notas, f_notas };